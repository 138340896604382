import $ from "jquery";
import "bootstrap";
import "lazysizes";
import "moment";
import "ua-parser-js";
import Alpine from "alpinejs";
import _ from "lodash";
import axios from "axios";

window.$ = $;
window.jQuery = $;
window.Alpine = Alpine;
window._ = _;
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Alpine.start();
